<template>
  <div class="stockOutDlt container_box details_box_styles">
    <!--返回按钮-->
    <div class="go_back" @click="backList">
      <Icon class="back_icon" type="ios-arrow-back"/>
      <span class="back_txt">{{ $t('key1001867') }}</span>
    </div>
    <!--发货的进度条-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <h4 class="progress_title">{{ $t('key1005765') + mainInfo.pickingNo }}</h4>
        <div class="progress_bar_default" v-if="detailApiObj.outboundType !== 3">
          <Steps
            v-if="currentIconStatus.createdTime ||
          currentIconStatus.pickingTime ||
          currentIconStatus.getLabelTime ||
          currentIconStatus.pickingGoodsTime" :current=" workType === 'sellStock'?
          currentIconStatus.salesPickingStatus:
          currentIconStatus.otherPickingStatus">
            <!--已创建-->
            <Step :title="$t('key1001222')" icon="md-cart" :content="currentIconStatus.createdTime"></Step>
            <!--已上传物流商 getLabelTime-->
            <Step :title="$t('key1005763')" icon="md-car" v-if="workType === 'sellStock'
          && (currentIconStatus.pickingTime && currentIconStatus.getLabelTime &&
                currentIconStatus.pickingTime > currentIconStatus.getLabelTime)"
              :content="currentIconStatus.getLabelTime">
            </Step>
            <!--已配货 pickingTime -->
            <Step :title="$t('key1003462')" icon="md-cash" :content="currentIconStatus.pickingTime"></Step>
            <!--已拣货 pickingGoodsTime-->
            <Step :title="$t('key1001224')" icon="ios-basket" v-if="(currentIconStatus.pickingGoodsTime && currentIconStatus.getLabelTime &&
          currentIconStatus.pickingGoodsTime < currentIconStatus.getLabelTime)"
              :content="currentIconStatus.pickingGoodsTime">
            </Step>
            <!--已上传物流商 getLabelTime-->
            <Step :title="$t('key1005763')" icon="md-car" v-if="workType === 'sellStock' && (!currentIconStatus.pickingTime ||
          !currentIconStatus.getLabelTime || currentIconStatus.pickingTime <= currentIconStatus.getLabelTime)"
              :content="currentIconStatus.getLabelTime">
            </Step>
            <!--已拣货 pickingGoodsTime-->
            <Step :title="$t('key1001224')" icon="ios-basket" v-if="!currentIconStatus.pickingGoodsTime ||
          !currentIconStatus.getLabelTime || (currentIconStatus.pickingGoodsTime >= currentIconStatus.getLabelTime)"
              :content="currentIconStatus.pickingGoodsTime">
            </Step>
            <!--打印 printTime-->
            <Step :title="$t('key1002511')" icon="md-print" v-if="workType === 'sellStock'" :content="currentIconStatus.printTime"></Step>
            <!--已装箱-->
            <Step :title="$t('key1001226')" icon="md-cube" v-if="workType === 'otherStock' && detailApiObj.needBox === 1" :content="detailApiObj.boxFinishTime"></Step>
            <!--已发货-->
            <Step :title="$t('key1001227')" icon="ios-send" :content="currentIconStatus.despatchTime"></Step>
          </Steps>
        </div>
        <!--三方仓备货的只有已创建和已发货-->
        <div class="progress_bar_trilateral" v-else>
          <Steps :current="outboundTypeCurrent">
            <Step :title="$t('key1001222')" icon="md-cart" :content="currentIconStatus.createdTime"></Step>
            <Step :title="$t('key1001227')" icon="ios-send" :content="currentIconStatus.despatchTime"></Step>
          </Steps>
        </div>
      </Card>
    </div>
    <!--主信息、收货人信息、物流商信息-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <Tabs value="main" @on-click="tabClick">
          <TabPane :label="$t('key1003420')" name="main">
            <Row type="flex" :gutter="gutterItem">
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1005766') }}</span>
                <span class="basic_txt">{{ mainInfo.pickingNo }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1002506') }}</span>
                <span class="basic_txt">{{ mainInfo.pickingGoodsNo }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="workType === 'otherStock'">
                <span class="basic_title">{{ $t('key1003586') }}</span>
                <span class="basic_txt">{{ mainInfo.outboundOrderStatusTxt }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1003446') }}</span>
                <span class="basic_txt">{{ mainInfo.warehouseName }}</span>
              </Col>
              <!--其他出库显示参考编号-->
              <Col :span="ItemCol" class="basic_item" v-if="workType === 'otherStock'">
                <span class="basic_title">{{ $t('key1005767') }}</span>
                <span class="basic_txt">{{ mainInfo.referenceNo }}</span>
                <span class="basic_txt" style="marginLeft:10px;">{{ mainInfo.shipmentId }}</span>
              </Col>
              <Col span="6" v-if="workType === 'otherStock'">
                <span class="basic_title">{{ $t('key1005768') }}</span>
                <span class="basic_txt">{{ mainInfo.pickingGoodsStatusTxt }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1000870') }}</span>
                <span class="basic_txt">{{ mainInfo.createTime }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1002164') }}</span>
                <span class="basic_txt">{{ mainInfo.createName }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="workType === 'otherStock' && detailApiObj.needBox === 1">
                <span class="basic_title">{{ $t('key1005769') }}</span>
                <span class="basic_txt">{{ mainInfo.boxStatusTxt }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1005770') }}</span>
                <span class="basic_txt">{{ mainInfo.lastModifyTime }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1005771') }}</span>
                <span class="basic_txt">{{ mainInfo.updateName }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="workType === 'sellStock'">
                <span class="basic_title">{{ $t('key1005772') }}</span>
                <span class="basic_txt">{{ mainInfo.totalWeight }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="workType === 'sellStock'">
                <span class="basic_title">{{ $t('key1005773') }}</span>
                <span class="basic_txt">{{ mainInfo.userWeight }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="workType === 'sellStock'">
                <span class="basic_title">{{ $t('key1005774') }}</span>
                <span class="basic_txt">{{ mainInfo.estimateFreight }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="workType === 'sellStock'">
                <span class="basic_title">{{ $t('key1005775') }}</span>
                <span class="basic_txt">{{ mainInfo.postage }}</span>
              </Col>
            </Row>
          </TabPane>
          <TabPane :label="$t('key1003421')" name="receiver">
            <Row type="flex" :gutter="gutterItem">
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1003447') }}</span>
                <Input type="text" clearable v-if="otherIsEdit" v-model.trim="mainInfo.consigneeName" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt">{{ mainInfo.consigneeName }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1003448') }}</span>
                <Select v-if="otherIsEdit" v-model="mainInfo.consigneeCountry" style="width: 220px" filterable>
                  <Option v-for="(item, index) in countrysData" :key="index" :value="item.twoCode">{{ item.cnName }}</Option>
                </Select>
                <span class="basic_txt" v-else>{{ mainInfo.consigneeCountry }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="workType !== 'otherStock'">
                <span class="basic_title">{{ $t('key1003449') }}</span>
                <span class="basic_txt">{{ mainInfo.buyerPassportCode }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1002300') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.consigneeLinkMan" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.consigneeLinkMan }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1001904') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.consigneeProvince" :maxlength="50" style='width: 200px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.consigneeProvince }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="workType !== 'otherStock'">
                <!--IDY-2016  这里现在只做了一部分，只在除了其他出库详情做展示，后续需要在创建其他出库单和其他出库单详情加上编辑-->
                <span class="basic_title">{{ $t('key1002301') }}</span>
                <span class="basic_txt">{{ mainInfo.consigneeLinkMobile }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1001946') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.consigneeLinkTel" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.consigneeLinkTel }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1001905') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.consigneeCity" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.consigneeCity }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1001911') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.consigneeMail" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.consigneeMail }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1003450') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.consigneeAddress" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.consigneeAddress }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1003451') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.consigneeAddressOther" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.consigneeAddressOther }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1001909') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.consigneeZipCode" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.consigneeZipCode }}</span>
              </Col>
            </Row>
          </TabPane>
          <TabPane :label="$t('key1003422')" name="logistic">
            <Row type="flex" :gutter="gutterItem">
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1001134') }}</span>
                <Select v-if="otherIsEdit" v-model="mainInfo.logisticsDealerCode" label-in-value style="width: 220px"
                  filterable @on-change="logisticDealerChange">
                  <Option v-for="v in apiLogisterList" :value="v.code" :key="v.code">{{ v.name }}</Option>
                </Select>
                <span class="basic_txt" v-else>{{ mainInfo.logisticsDealerName }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1003452') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.logisticsNo" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.logisticsNo }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1003453') }}</span>
                <Select v-model="mainInfo.logisticsMailCode" v-if="otherIsEdit" style="width: 220px"
                  label-in-value filterable :placeholder="$t('key1005764')">
                  <Option v-for="v in apiSendTypeList" :value="v.code" :key="v.code">{{ v.name }}</Option>
                </Select>
                <span class="basic_txt" v-else>{{ mainInfo.logisticsMailName }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1003454') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.logisticsTrackingNo" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.logisticsTrackingNo }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1003455') }}</span>
                <span class="basic_txt">{{ mainInfo.virtualTrackingNumber }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="workType === 'otherStock'">
                <span class="basic_title">{{ $t('key1002300') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.logisticsLinkMan" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.logisticsLinkMan }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="workType === 'otherStock'">
                <span class="basic_title">{{ $t('key1001946') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.logisticsLinkTel" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.logisticsLinkTel }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1001911') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.logisticsLinkMail" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.logisticsLinkMail }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="workType === 'sellStock'">
                <span class="basic_title">{{ $t('key1005776') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.carrierWeight" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.carrierWeight }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item">
                <span class="basic_title">{{ $t('key1003457') }}</span>
                <Input v-if="otherIsEdit" clearable v-model.trim="mainInfo.postage" :maxlength="50" style='width: 220px'></Input>
                <span class="basic_txt" v-else>{{ mainInfo.postage }}</span>
              </Col>
              <Col :span="ItemCol" class="basic_item" v-if="mainInfo.packageCarrierParamList"
                v-for="(item, index) in mainInfo.packageCarrierParamList" :key="index">
                <span class="basic_title" v-if="item.paramValue">{{ item.paramKey + '：' }}</span>
                <span class="basic_txt" v-else>{{ item.paramValue }}</span>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Card>
    </div>
    <!--出库单列表-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <Table :columns="columns1"
          highlight-row
          border
          :data="deliveryListData"
          max-height="400"
          @on-selection-change="sltTableItemFn">
        </Table>
      </Card>
    </div>
    <!--分配列表-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1005777') }}</h2>
        </template>
        <Table highlight-row border max-height="400" :columns="columns2" :data="data2"></Table>
      </Card>
    </div>
    <!-- 操作日志 -->
    <optionDaily
      :operationLog="optionNoteData"
      @showNoteFunc="showNoteFunc"
      from="outStock"
      :params="{ pickingId, relatedBusinessNo: mainInfo.pickingNo }" @addSuccessBack="addSuccessBack" :falgType="falgType">
    </optionDaily>
  </div>
</template>
<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import api from '@/api/';
import Mixin from '@/components/mixin/common_mixin';
import optionDaily from '@/views/supplier/components/wms/common/commonDaily';

export default {
  components: {
    optionDaily
  },
  mixins: [Mixin],
  data() {
    var v = this;
    return {
      ItemCol: 6,
      pickingNo: null,
      waitLoading: false,
      warehouseOverseaType: '',
      workType: null,
      ymsMerchant: false,
      queryPickingBatchResult: [],
      apiSendTypeList: [],
      falgType: 'otherStockOut',
      currentIconStatus: {},
      pageParams: {},
      apiLogisterList: [], // 物流商下拉
      cacheOptionNoteData: [], // 缓存留言信息以便过滤
      pickingId: '', // 新增留言需要带的参数
      sltTableItem: [], // 表格选择的数据
      wareId: '',
      clickIndex: 0,
      outListTypeList: [
        {
          label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001244'),
          value: 'S1'
        }, {
          label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005778'),
          value: 'O3'
        }, {
          label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000563'),
          value: 'O1'
        }, {
          label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005779'),
          value: 'O2'
        }
      ],
      mainInfo: {
        consigneeName: '',
        consigneeCountry: null,
        consigneeLinkMan: '',
        consigneeProvince: '',
        consigneeLinkTel: '',
        consigneeCity: '',
        consigneeAddress: '',
        consigneeMail: '',
        consigneeAddressOther: '',
        consigneeZipCode: '',

        logisticsNo: '',
        logisticsTrackingNo: '',
        logisticsLinkMan: '',
        logisticsLinkTel: '',
        logisticsLinkMail: '',
        carrierWeight: '',
      },
      columns1: [
        {
          type: 'selection',
          width: 60,
          align: 'center',
          fixed: 'left'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003427'),
          width: 70,
          align: 'center',
          fixed: 'left',
          type: 'index',
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005780'),
          align: 'center',
          width: 100,
          key: 'perRowStatus'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005781'),
          align: 'center',
          minWidth: 140,
          resizable: true,
          key: 'outOfStockStatus',
          render(h, params) {
            if (params.row.outOfStockStatus) {
              let obj = v.failTypeList.filter(j => {
                if (j.value === params.row.outOfStockStatus) {
                  return j;
                }
              })[0];
              return h('Tag', {
                props: {
                  color: obj.color
                }
              }, obj.label);
            }
          }
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002470'),
          key: 'b',
          width: 120,
          align: 'center',
          render: (h, params) => {
            return v.tableImg(h, params, 'goodsUrl');
          }
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005782'),
          align: 'center',
          width: 120,
          key: 'goodsSku'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000192'),
          align: 'center',
          width: 150,
          key: 'goodsCnDesc'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002933'),
          align: 'center',
          width: 120,
          key: 'expectedNumber',
          renderHeader(h, params) {
            let isCheck = false;
            if (v.warehouseOverseaType === 'AMAZON_FBA' && v.otherIsEdit) {
              return h('div', [
                h('p', alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002933')), h('p', {}, [
                  h('Checkbox', {
                    props: {
                      value: isCheck
                    },
                    on: {
                      'on-change'(val) {
                        isCheck = val;
                        if (val) {
                          v.deliveryListData.forEach(i => {
                            i.expectedNumberInit = i.expectedNumber;
                            i.expectedNumber = i.plannedAllotInventoryNumber;
                          });
                        } else {
                          v.deliveryListData.forEach(i => {
                            i.expectedNumber = i.expectedNumberInit;
                          });
                        }
                      }
                    }
                  }, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005783'))
                ])
              ]);
            } else {
              return h('p', alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002933'));
            }
          },
          render(h, params) {
            if (v.otherIsEdit) {
              return h('Input', {
                props: {
                  value: params.row.expectedNumber
                },
                style: {
                  width: '90px',
                  margin: '0 auto'
                },
                on: {
                  'on-change'(val) {
                    params.row.expectedNumber = val.target.value;
                    v.deliveryListData[params.index] = params.row;
                  }
                }
              });
            } else {
              return h('span', params.row.expectedNumber);
            }
          }
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005784'),
          align: 'center',
          width: 110,
          key: 'doneDeliveredNumber'
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005785'),
          align: 'center',
          width: 100,
          key: 'mobileImies',
          render: (h, params) => {
            let list = [];
            let data = params.row.mobileImies;
            if (data) {
              data.map((item) => {
                list.push(item.mobileImieCode);
              });
            }
            return h('div', list.join());
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005786'),
          align: 'center',
          width: 120,
          key: 'doneAssignedNumber'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005787'),
          align: 'center',
          width: 120,
          key: 'notAssignedNumber'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002471'),
          align: 'center',
          width: 120,
          key: 'actualPickingNumber'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005788'),
          width: 120,
          align: 'center',
          key: 'warehouseLocationName',
          render: (h, params) => {
            let productGoodsId = v.deliveryListData[params.index].productGoodsId;
            let warehouseLocationName = '';
            if (v.queryPickingBatchResult.length > 0) {
              v.queryPickingBatchResult.map((item) => {
                if (item.productGoodsId === productGoodsId) {
                  warehouseLocationName = item.warehouseLocationName;
                }
              });
            } else {
              warehouseLocationName = v.deliveryListData[params.index].warehouseLocationName;
            }
            return h('span', warehouseLocationName);
          }
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002472'),
          align: 'center',
          width: 90,
          key: 'goodsVolume'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000710'),
          align: 'center',
          width: 90,
          key: 'goodsWeight'
        }
      ],
      deliveryListData: [],
      columns2: [
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003427'),
          width: 90,
          align: 'center',
          type: 'index',
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005782'),
          align: 'center',
          key: 'goodsSku'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000192'),
          align: 'center',
          key: 'goodsCnDesc',
          render: (h, params) => {
            return h('Poptip', {
              props: {
                content: params.row.goodsCnDesc,
                trigger: 'hover'
              }
            }, [h('span', {}, params.row.goodsCnDesc)]);
          }
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005789'),
          align: 'center',
          key: 'receiptBatchNo'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005788'),
          align: 'center',
          key: 'warehouseLocationName'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000207'),
          align: 'center',
          key: 'batchNumber'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005790'),
          align: 'center',
          sortable: true,
          key: 'createdTime'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000221'),
          align: 'center',
          key: 'createdBy'
        }
      ],
      data2: [],
      openEditStatus: false,
      showNotes: true,
      addNotesInput: '',
      optionNoteData: [],
      detailApiObj: '',
      ruleMainInfo: {},
      boxDetailData: [],
      carrierId: '',
      outboundTypeCurrent: 0
    };
  },
  computed: {
    otherIsEdit() {
      // 其他出库 未出库状态
      return this.workType === 'otherStock' && this.mainInfo.pickingStatus === '0' && this.getPermission('wmsPicking_updatePicking');
    },
    outListTypeListLabel() {
      let obj = null;
      if (this.outListTypeList) {
        obj = this.outListTypeList.find(val => val.value === this.mainInfo.pickingType);
      }
      return obj && obj.label ? obj.label : '';
    },
  },
  created() {
    let data = this.$route.query;
    if (data) {
      this.workType = data.workType ? data.workType : null;
      this.pickingNo = data.pickingNo ? data.pickingNo : null;
    }
    /** 销售出库要隐藏体积和重量 **/
    if (this.workType === 'sellStock') this.columns1.splice(-3, 2);
    this.searchData();
    this.getCurrencyInfo();
    // 清空store的otherStockInfo，这个数据在库存调拨存，这里需要清掉
    // this.$store.commit('otherStockInfo', null);
  },
  methods: {
    // 切换tab栏
    tabClick(name) {
      if (name === 'logistic') {
        let merchantId = this.$store.state.erpConfig.userInfo.merchantId;
        this.axios.get(api.get_carrier_queryCarrier + `?merchantId=${merchantId}`).then(res => {
          if (res.data.code === 0) {
            this.apiLogisterList = res.data.datas;
            if (this.mainInfo.logisticsDealerCode) {
              let obj = this.apiLogisterList.filter(i => {
                return i.carrierId === this.mainInfo.logisticsDealerCode;
              });
              this.logisticDealerChange({
                value: obj[0].carrierId,
                code: obj[0].carrierId
              }, merchantId);
            }
          }
        });
      } else if (name === 'receiver') {
        this.getCountrysData();
      }
    },
    logisticDealerChange(item, merchantId) {
      this.carrierId = this.apiLogisterList.find(val => {
        return val.carrierId === item.value;
      }).carrierId;
      this.axios.get(api.get_carrierShippingMethod_queryCarrierShippingMethodByCarrierId + `?carrierId=${this.carrierId}&merchantId=${merchantId}`).then(res => {
        if (res.data.code === 0) {
          this.apiSendTypeList = res.data.datas;
        }
      });
    },
    showNoteFunc(bool) {
      if (!bool) {
        let optionData = [...this.optionNoteData];
        this.optionNoteData = optionData.filter(val => {
          return val.logTypeDesc !== '10';
        });
      } else {
        this.searchData();
      }
    },
    // 添加备注成功后，刷新列表数据
    addSuccessBack() {
      this.searchData();
    },
    fromStatusCloseOption() {
      if (this.workType === 'sellStock') {
        if (this.currentIconStatus.salesPickingStatus > 2) {
          this.columns1.splice(-1, 1);
          this.columns1.splice(0, 1);
        }
      } else {
        if (this.currentIconStatus.otherPickingStatus > 1) {
          this.columns1.splice(-1, 1);
          this.columns1.splice(0, 1);
        }
      }
    },
    // 获取详情数据
    searchData() {
      let pos = [];
      var obj = {
        pickingNo: this.pickingNo,
        warehouseId: this.getWarehouseId()
      };
      this.$store.commit('changeTopMenu', false);
      this.axios.post(api.get_sellStockOutDtl, obj).then(res => {
        if (res.data.code === 0) {
          let resData = res.data.datas;
          this.detailApiObj = res.data.datas;
          // 出库单状态
          let outboundOrderArr = [
            {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005791'), value: '0'},
            {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005792'), value: '1'},
            {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005793'), value: '2'},
            {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001241'), value: '3'},
            {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005794'), value: '4'},
            {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005795'), value: '5'},
          ];
          outboundOrderArr.map((item) => {
            if (item.value === resData.status) {
              resData.outboundOrderStatusTxt = item.title;
            }
          });
          // 拣货状态
          let pickingGoodsArr = [
            {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002473'), value: '0'},
            {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001224'), value: '1'}
          ];
          pickingGoodsArr.map((item) => {
            if (item.value === resData.pickingGoodsStatus) {
              resData.pickingGoodsStatusTxt = item.value;
            }
          });
          // 装箱状态
          let boxStatusArr = [
            {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005796'), value: 0},
            {title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001226'), value: 2}
          ];
          boxStatusArr.map((item) => {
            if (item.value === resData.boxStatus) {
              resData.boxStatusTxt = item.title;
            }
          });
          this.wareId = resData.warehouseId;
          resData.createTime = this.$uDate.dealTime(resData.createdTime);
          resData.lastModifyTime = this.$uDate.dealTime(resData.updatedTime);
          resData.totalWeight = resData.totalWeight ? resData.totalWeight + '(g)' : '';
          resData.estimateFreight = resData.estimateFreight ? resData.estimateFreight + this.currencyState : '';
          resData.carrierWeight = resData.carrierWeight ? resData.carrierWeight + '(g)' : '';
          this.mainInfo = resData;
          if (resData.postage) {
            if (resData.postageCurrency) {
              this.mainInfo.postage = resData.postage + '' + resData.postageCurrency;
            } else {
              this.mainInfo.postage = resData.postage;
            }
          }
          this.pickingId = resData.pickingId;
          let goodsArr = resData.queryPickingGoodsResult;
          if (goodsArr && goodsArr.length > 0) {
            goodsArr.forEach(val => {
              val.pickingNo = resData.pickingNo;
              outboundOrderArr.map((item) => {
                if (val.pickingDetailStatus === item.value) {
                  val.perRowStatus = item.title;
                }
              });
            });
            this.queryPickingBatchResult = resData.queryPickingBatchResult;
            this.deliveryListData = goodsArr;
            this.data2 = this.processTimeData(resData.queryPickingBatchResult, 'createdTime');
            if (resData.wmsPickingBoxDetails) {
              resData.wmsPickingBoxDetails.forEach((n, i) => {
                pos.push(n.updatedBy);
              });
            }
            if (resData.wmsPickingBoxDetails) {
              this.boxDetailData = this.processTimeData(resData.wmsPickingBoxDetails, 'createdTime');
            }
            resData.wmsPickingLog.forEach((n, i) => {
              n.updatedTime = this.$uDate.dealTime(n.updatedTime);
            });
            this.cacheOptionNoteData = resData.wmsPickingLog;
            this.optionNoteData = this.processTimeData(resData.wmsPickingLog, 'createdTime'); // 留言信息
          }
        } else {
          this.$Message.warning({
            content: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005797'),
            duration: 3
          });
        }
      });
      // 查询状态
      this.axios.get(api.getSalesPickingStatus + this.pickingNo).then(res => {
        if (res.data.code === 0) {
          let obj = {...res.data.datas};
          obj.createdTime = obj.createdTime
            ? this.$uDate.dealTime(obj.createdTime)
            : '';
          obj.despatchTime = obj.despatchTime
            ? this.$uDate.dealTime(obj.despatchTime)
            : '';
          obj.pickingGoodsTime = obj.pickingGoodsTime
            ? this.$uDate.dealTime(obj.pickingGoodsTime)
            : '';
          obj.pickingTime = obj.pickingTime
            ? this.$uDate.dealTime(obj.pickingTime)
            : '';
          obj.printTime = obj.printTime
            ? this.$uDate.dealTime(obj.printTime)
            : '';
          obj.getLabelTime = obj.getLabelTime
            ? this.$uDate.dealTime(obj.getLabelTime)
            : '';
          this.currentIconStatus = obj;
          this.currentIconStatus.salesPickingStatus = -1;
          this.currentIconStatus.otherPickingStatus = Number(obj.otherPickingStatus);
          delete obj.trackingNumberTime; // 这个字段暂时没用且会影响步骤条，所以这里删掉
          for (let key in obj) {
            if (key.indexOf('Time') > -1 && obj[key]) {
              this.currentIconStatus.salesPickingStatus++;
              // this.currentIconStatus.otherPickingStatus++;
            }
          }
          // 有装箱情况下的步骤条
          if (Number(obj.otherPickingStatus) === 3 && this.detailApiObj.needBox === 1) {
            this.currentIconStatus.otherPickingStatus = 4; // 已发货
          } else if (Number(obj.otherPickingStatus) === 2 && this.detailApiObj.needBox === 1 && this.detailApiObj.boxStatus === 2) {
            this.currentIconStatus.otherPickingStatus = 3; // 已发货
          }
          if (obj.createdTime) {
            this.outboundTypeCurrent = 0;
          }
          if (obj.despatchTime) {
            this.outboundTypeCurrent = 1;
          }
          this.fromStatusCloseOption();
        }
      });
    },
    // 添加备注按钮
    addMsg() {
      if (!this.addNotesInput) {
        this.$Message.warning({
          content: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002849'),
          duration: 3
        });
        return;
      }
      let obj = {
        createdBy: null,
        createdTime: null,
        logContentDesc: this.addNotesInput
          ? this.addNotesInput
          : null,
        logTypeDesc: null,
        merchantId: null,
        pickingId: this.pickingId,
        pickingLogId: null,
        relatedBusinessNo: this.mainInfo.pickingNo,
        updatedBy: null,
        updatedTime: null
      };
      this.$Spin.show();
      this.axios.post(api.add_wmsPicking_createLog, obj).then(res => {
        this.$Spin.hide();
        if (res.data.code === 0) {
          this.$Message.success(alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000501'));
          this.refreshMsgNote();
        } else {
          this.$Message.warning({
            content: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003916'),
            duration: 3
          });
        }
      }).catch(() => {
        this.$Spin.hide();
      });
    },
    // 返回列表
    backList() {
      let data = this.$route.query;
      if (data.switchTalg === 'message') {
        this.$router.push('/wms/searchPackage');
      } else {
        this.$router.go(-1);
      }
    },
    // 勾选列表数据
    sltTableItemFn(data) {
      this.sltTableItem = data;
    },
    // 添加成功后刷新留言
    refreshMsgNote() {
      this.axios.get(api.refresh_msgNote + '?pickingId=' + this.pickingId).then(res => {
        if (res.data.code === 0) {
          this.optionNoteData = res.data.datas;
          this.cacheOptionNoteData = res.data.datas; // 更新缓存
          this.addNotesInput = '';
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.details_box_styles {
  .progress_title {
    font-size: 17px;
    padding-bottom: 15px;
    color: #333;
    font-weight: bold;
    text-align: center;
  }

  .progress_bar_default {
    width: 1000px;
    margin: 0 auto;
  }

  .progress_bar_trilateral {
    width: 600px;
    margin: 0 auto;
  }
}
</style>
